import React from 'react';
import './App.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 alameducity.com, Learn, Excel and Enlighten. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
